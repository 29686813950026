import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PageTransition from 'gatsby-plugin-page-transitions'
import Layout from '../components/layout'
import Page from '../components/Page'

const SecondPage = ({ transition, data }) => {
  return (
    <Layout>
      <Page
        transition={transition}
        bg={data.datoCmsPage.background ? data.datoCmsPage.background.url : ''}
        align={data.datoCmsPage.alignment}
        hotspots={data.datoCmsPage.treeChildren}
        hotspotLabel={false}
        logoColor={data.datoCmsPage.logoColor}
        icon={data.datoCmsPage.icon}
        backLink={
          data.datoCmsPage.treeParent ? data.datoCmsPage.treeParent.slug : '/'
        }
        backLinkX={data.datoCmsPage.backLinkX}
        backLinkY={data.datoCmsPage.backLinkY}
        backButton={true}
        lvl={1}
        menuData={{ sub: true, ...data.menuData }}
        currentSlug={data.datoCmsPage.slug}
        attachments={data.datoCmsPage.attachments || []}
        showDachStory={true}
      />
    </Layout>
  )
}
export default SecondPage

export const query = graphql`
  query Page2Query($slug: String) {
    datoCmsPage(slug: { eq: $slug }) {
      title
      slug
      displayTitle
      icon {
        url
      }
      menuIcon {
        url
      }
      backLink {
        slug
      }
      backLinkX
      backLinkY
      background {
        url
      }
      alignment
      logoColor
      treeParent {
        slug
      }
      attachments {
        __typename
        ... on DatoCmsVideo {
          title
          videoUrl
          thumbnail {
            url
            width
            height
          }
        }
        ... on DatoCmsPdf {
          title
          filename
          thumbnail {
            url
            width
            height
          }
        }
      }
      treeChildren {
        title
        displayTitle
        hotspotX
        hotspotY
        slug
        icon {
          url
        }
        treeChildren {
          title
          displayTitle
          slug
        }
      }
    }
    menuData: datoCmsPage(root: { eq: true }) {
      treeChildren {
        displayTitle
        slug
        menuIcon {
          url
        }
        treeChildren {
          displayTitle
          slug
          treeChildren {
            displayTitle
            slug
          }
        }
      }
    }
  }
`
